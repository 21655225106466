import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import GroupPurchaseCommanderComponent from '../components/group_purchase_commander';

const GroupPurchaseCommanderManagePage = () => (
  <SiteLayout>
    <SEO title="团购团长" />
    <GroupPurchaseCommanderComponent></GroupPurchaseCommanderComponent>
  </SiteLayout>
)

export default GroupPurchaseCommanderManagePage;
